#AppHeader {
  z-index: 10000;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  height: 60pt;
  padding-top: 8pt;
  padding-left: 8pt;
  display: flex;
  position: sticky;
  top: 0;
}

#AppHeader h1 {
  color: var(--primaryTextColor);
  flex-grow: 1;
  margin-left: 8pt;
  font-size: 1.5em;
}

#AppHeader img {
  border-radius: 4pt;
  width: 48pt;
  height: 48pt;
  margin: .5rem;
}

#searchBar {
  color: var(--primaryTextColor);
  border: 1pt solid var(--primaryTextColor);
  border-radius: 4pt;
  width: 256pt;
  margin-left: auto;
  margin-right: 16pt;
  padding: 8pt;
  font-size: 16px;
}

#searchBar:focus {
  border: 1pt solid var(--accentColor);
  outline: none !important;
}

.header-right {
  align-items: center;
  display: flex;
}

#EventHistoryButton {
  cursor: pointer;
  background-color: #0000;
  border: none;
  justify-content: center;
  align-items: center;
  width: 32pt;
  height: 32pt;
  margin-right: 8pt;
  padding: 0;
  display: flex;
}

#EventHistoryButton img {
  width: 24pt;
  height: 24pt;
}
/*# sourceMappingURL=index.f530307d.css.map */
