#AppHeader {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    z-index: 10000;
    height: 60pt;
    background-color: white;
    align-items: center;
    padding-left: 8pt;
    padding-top: 8pt;
}

#AppHeader h1 {
    margin-left: 8pt;
    font-size: 1.5em;
    color: var(--primaryTextColor);
    flex-grow: 1;
}

#AppHeader img {
    width: 48pt;
    height: 48pt;
    margin: 0.5rem;
    border-radius: 4pt;
}

#searchBar {
    padding: 8pt;
    font-size: 16px;
    color: var(--primaryTextColor);
    border: 1pt solid var(--primaryTextColor);
    border-radius: 4pt;
    margin-left: auto;
    margin-right: 16pt;
    width: 256pt;
}

#searchBar:focus {
    outline: none !important;
    border: 1pt solid var(--accentColor);
}

.header-right {
    display: flex;
    align-items: center;
}


#EventHistoryButton {
    width: 32pt;
    height: 32pt;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 8pt;
}

#EventHistoryButton img {
    width: 24pt;
    height: 24pt;
}